/* Type imports ---------------------------------------- */
import type { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if(onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(
        (
          {
            getCLS,
            getFID,
            getFCP,
            getLCP,
            getTTFB,
          },
        ) => {
          getCLS(onPerfEntry);
          getFID(onPerfEntry);
          getFCP(onPerfEntry);
          getLCP(onPerfEntry);
          getTTFB(onPerfEntry);
        },
      )
      .catch(
        (pException) => {
          console.error(`[ERROR] <reportWebVitals> import('web-vitals') failed :`, pException);
        },
      );
  }
};

export default reportWebVitals;
