/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DriverList from './DriverList/DriverList';

/* Type imports ---------------------------------------- */
import type { DriverData } from '../../../types/DriverData';
import {
  EventFieldsFragment,
  PartnerFieldsFragment,
} from '../../../apollo/queries/types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

/* DriverSearchSideBar component ----------------------- */
interface DriverSearchSideBarProps {
  driversList: DriverData[];
  selectedDriverID: number;
  setSelectedDriverID: (pDriverID: number) => void;
  searchBarContents: string;
  setSearchBarContents: (newValue: string) => void;
  selectedPartner: number;
  setSelectedPartner: (newValue: number) => void;
  selectedEvent: number;
  setSelectedEvent: (newValue: number) => void;
  partners: PartnerFieldsFragment[];
  events: EventFieldsFragment[];
}

/* DriverSearchSideBar component ----------------------- */
const DriverSearchSideBar: React.FC<DriverSearchSideBarProps> = (
  {
    driversList,
    selectedDriverID,
    setSelectedDriverID,
    searchBarContents,
    setSearchBarContents,
    selectedPartner,
    setSelectedPartner,
    selectedEvent,
    setSelectedEvent,
    partners,
    events,
  },
) => {

  const onSearchBarContentChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (pEvent) => {
    setSearchBarContents(pEvent.target.value);
  };

  const clearSearchBarContents: React.MouseEventHandler<HTMLButtonElement> = (pEvent) => {
    setSearchBarContents('');
  };

  const handleMouseDownSearchBarClear: React.MouseEventHandler<HTMLButtonElement> = (pEvent) => {
    pEvent.preventDefault();
  };

  const onResetSelectedDriver: React.MouseEventHandler<HTMLButtonElement> = (pEvent) => {
    setSelectedDriverID(-1);
  };

  return (
    <div
      style={
        {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          padding: '1rem',
          justifyContent: 'start',
        }
      }
    >
      <TextField
        id="driver-search-bar-input"
        variant="outlined"
        fullWidth
        label="Search for a driver"
        InputLabelProps={{ shrink: true }}
        disabled={driversList.length < 1}
        value={searchBarContents}
        onChange={onSearchBarContentChanged}
        InputProps={
          {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            endAdornment: searchBarContents ?
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear driver search bar contents"
                  onClick={clearSearchBarContents}
                  onMouseDown={handleMouseDownSearchBarClear}
                  edge="end"
                >
                  <ClearIcon color="primary" />
                </IconButton>
              </InputAdornment> :
              undefined,
          }
        }
      />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 0 }}>
        <FormControl
          variant="outlined"
          style={{ flex: 1, marginRight: '7px' }}
        >
          <InputLabel
            id="partner-select-label"
            style={{ background: '#171A1D', padding: '0 3px' }}
          >
            Event
          </InputLabel>
          <Select
            value={selectedEvent}
            onChange={(event) => setSelectedEvent(event.target.value as number)}
            IconComponent={() => <ArrowDropDown htmlColor="white" />}
          >
            <MenuItem
              value={0}
            >
              All events
            </MenuItem>
            {
              events.map((event) => (
                <MenuItem
                  key={event.id}
                  value={event.id}
                >
                  {event.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ flex: 1, marginLeft: '7px' }}
        >
          <InputLabel
            id="partner-select-label"
            style={{ background: '#171A1D', padding: '0 3px' }}
          >
            Partner
          </InputLabel>
          <Select
            labelId="partner-select-label"
            value={selectedPartner}
            onChange={(event) => setSelectedPartner(event.target.value as number)}
            IconComponent={() => <ArrowDropDown htmlColor="white" />}
          >
            <MenuItem
              value={0}
            >
              All partners
            </MenuItem>
            {
              partners.map((partner) => (
                <MenuItem
                  key={partner.id}
                  value={partner.id}
                >
                  {partner.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      {
        selectedDriverID !== undefined && selectedDriverID > 0 ?
          <Button
            size="large"
            color="default"
            variant="contained"
            disabled={selectedDriverID === undefined || selectedDriverID < 1}
            onClick={onResetSelectedDriver}
          >
            Unselect driver
          </Button> :
          null
      }
      <DriverList
        driversList={driversList}
        setSelectedDriverID={setSelectedDriverID}
      />
    </div>
  );
};

/* Export DriverSearchSideBar component ---------------- */
export default DriverSearchSideBar;
