/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';

/* Module imports -------------------------------------- */
import { useGoogleGeocoder } from '../../../utils/GoogleGeocoderProvider';

/* Component imports ----------------------------------- */
import DriverInfoTable from './DriverInfoTable';

/* Type imports ---------------------------------------- */
import type { DriverData } from '../../../types/DriverData';
import type { GoogleGeocoderContextValue } from '../../../types/GoogleGeocoderContextValue';

/* Style imports --------------------------------------- */
import './DriverInfoWindow.css';

/* DriverInfoWindow component prop types --------------- */
interface DriverInfoWindowProps {
  selectedDriver: DriverData;
}

/* DriverInfoWindow component -------------------------- */
const DriverInfoWindow: React.FC<DriverInfoWindowProps> = ({ selectedDriver }) => {
  const {
    isLoaded: isGoogleGeocoderLoaded,
    geocoder,
  }: GoogleGeocoderContextValue = useGoogleGeocoder();

  const [ geocodingResults, setGeodingResults ] = useState<google.maps.GeocoderResult[]>([]);

  useEffect(
    () => {
      let lCompIsMounted = true;

      const getGeocodedLocationData = async () => {
        const lLat = selectedDriver.location?.coords?.latitude;
        const lLng = selectedDriver.location?.coords?.longitude;
        if(
          isGoogleGeocoderLoaded &&
          geocoder !== undefined &&
          lLat !== null &&
          lLat !== undefined &&
          lLng !== null &&
          lLng !== undefined
        ) {
          const lLatLng: google.maps.LatLngLiteral = {
            lat: lLat,
            lng: lLng,
          };

          const lGeocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode(
            {
              location: lLatLng,
            },
          );

          lCompIsMounted && setGeodingResults(lGeocoderResponse.results);
          return lGeocoderResponse.results;
        } else {
          lCompIsMounted && setGeodingResults([]);
          return [];
        }
      };

      getGeocodedLocationData()
        .then(
          (pResults) => {
            // console.log(`[DEBUG] <DriverInfoWindow> Geocoding results :`, pResults);
          },
        )
        .catch(
          (pException) => {
            console.error(`[ERROR] <DriverInfoWindow> getGeocodedLocationData failed :`, pException);
          },
        );

      return () => {
        lCompIsMounted = false;
      };
    },
    [
      isGoogleGeocoderLoaded,
      geocoder,
      selectedDriver.location?.coords?.latitude,
      selectedDriver.location?.coords?.longitude,
    ],
  );

  const getAddress = (pGeocodingInfo: google.maps.GeocoderResult[]): string | undefined => {
    let lAddress: string | undefined = undefined;
    let lRoute: string | undefined = undefined;
    let lLocality: string | undefined = undefined;
    let lCountry: string | undefined = undefined;

    for(let i = 0; i < pGeocodingInfo.length; ++i) {
      if(pGeocodingInfo[i].types.includes('street_address')) {
        lAddress = pGeocodingInfo[i].formatted_address;
      }
      if(pGeocodingInfo[i].types.includes('route')) {
        lRoute = pGeocodingInfo[i].formatted_address;
      }
      if(pGeocodingInfo[i].types.includes('locality')) {
        lLocality = pGeocodingInfo[i].formatted_address;
      }
      if(pGeocodingInfo[i].types.includes('country')) {
        lCountry = pGeocodingInfo[i].formatted_address;
      }
    }

    if(lAddress !== undefined && lAddress !== '') return lAddress;
    if(lRoute !== undefined && lRoute !== '') return lRoute;
    if(lLocality !== undefined && lLocality !== '') return lLocality;
    if(lCountry !== undefined && lCountry !== '') return lCountry;
  };

  return (
    <div className="DriverInfoWindow">
      <div
        className="DriverInfoWindowTitle"
        title={`ID : ${selectedDriver.id}`}
      >
        {`Driver : ${selectedDriver.displayName}`}
      </div>
      <div className="DriverInfoWindowSectionTitle">
        Location :
      </div>
      <div>
        {`Address : ${getAddress(geocodingResults)}`}
      </div>
      <div className="DriverInfoWindowSectionTitle">
        Passenger :
      </div>
      <div>
        {selectedDriver.currentRideId?.passengerId?.name}
      </div>
      <div>
        {`Company : ${selectedDriver.currentRideId?.parentId?.name}`}
      </div>
      <div>
        {`Division : ${selectedDriver.currentRideId?.divisionId?.name}`}
      </div>
      <div className="DriverInfoWindowSectionTitle">
        Driver info :
      </div>
      <DriverInfoTable selectedDriver={selectedDriver} />
    </div>
  );
};

/* Export DriverInfoWindow component ------------------- */
export default DriverInfoWindow;
