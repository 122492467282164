/* Framework imports ----------------------------------- */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* Module imports -------------------------------------- */
import { login } from '../../utils/authentication';
import setLoggedIn from '../../utils/store/loggedIn';

/* Component imports ----------------------------------- */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

/* Styling imports ------------------------------------- */
import useSignInStyles from './SignInPageStyle';

/* SignInPage component prop types --------------------- */
interface SignInPageProps {}

/* SignInPage component -------------------------------- */
const SignInPage: React.FC<SignInPageProps> = () => {
  const classes = useSignInStyles();

  const navigate = useNavigate();

  const [ email, setEmail ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const [ loginInProgress, setLoginInProgress ] = useState<boolean>(false);
  const [ loginFailed, setLoginFailed ] = useState<boolean>(false);

  const handleLogin = (event: React.MouseEvent) => {
    event.preventDefault();

    handleLoginLogic();
  };

  const handleLoginLogic = () => {
    /* Prevent concurrent requests */
    if(loginInProgress) {
      return;
    }

    setLoginInProgress(true);

    login(
      {
        email,
        password,
      },
    )
      .then(
        () => {
          setLoginFailed(false);

          console.log(`[DEBUG] <SignInPage.handleLoginLogic> Login succeeded.`);
          setLoggedIn(true);

          navigate('/');
        },
      )
      .catch(
        (error) => {
          setLoginFailed(true);

          console.log(`[DEBUG] <SignInPage.handleLoginLogic> Login failed :`, error);
          setLoggedIn(false);
          localStorage.removeItem('session_id');
        },
      )
      .finally(
        () => {
          setLoginInProgress(false);
        },
      );
  };

  return (
    <div className={classes.paper}>
      <Typography
        variant="h1"
        className={classes.margin}
      >
        Sign in
      </Typography>
      <form
        className={classes.form}
        noValidate
      >
        <TextField
          required
          id="email"
          label="Email Address"
          defaultValue={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={classes.margin}
        />
        <TextField
          required
          id="password"
          label="Password"
          defaultValue={password}
          onChange={(event) => setPassword(event.target.value)}
          onKeyDown={
            (e) => {
              if(e.key === 'Enter') {
                handleLoginLogic();
              }
            }
          }
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          fullWidth
          className={classes.margin}
          type="password"
        />
        <Button
          variant="contained"
          size="large"
          disabled={loginInProgress}
          onClick={handleLogin}
          style={{ width: '200px' }}
        >
          {
            !loginInProgress ?
              `Sign In` :
              <div
                style={
                  {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }
                }
              >
                {`Signing in...`}
                <CircularProgress
                  size="2rem"
                  style={{ marginLeft: '1rem' }}
                />
              </div>
          }
        </Button>
        {/* <Link href="#" variant="body2">
            Forgot password?
          </Link> */}
        <Snackbar
          open={loginFailed}
          autoHideDuration={6000}
          onClose={() => setLoginFailed(false)}
        >
          <Alert
            onClose={() => setLoginFailed(false)}
            variant="filled"
            severity="error"
          >
            Login failed
          </Alert>
        </Snackbar>
      </form>
    </div>
  );
};

/* Export SignInPage component ------------------------- */
export default SignInPage;
