/* Framework imports ----------------------------------- */
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

/* Module imports -------------------------------------- */
import {
  useCurrentUserQuery,
  useLoggedInQuery,
} from '../apollo/queries';
import setLoggedIn from '../utils/store/loggedIn';
import MainRouter from '../routers/MainRouter';

/* Component imports ----------------------------------- */
import Loader from '../components/Loader/Loader';

/* Styling imports ------------------------------------- */
import './App.css';

/* App component --------------------------------------- */
const App: React.FC = () => {
  const { data: loggedInData } = useLoggedInQuery();
  const isLoggedIn: boolean = loggedInData === undefined ?
    false :
    loggedInData.isLoggedIn;

  const {
    // data: currentUserData,
    loading: currentUserLoading,
    error: currentUserError,
  } = useCurrentUserQuery(
    {
      skip: !isLoggedIn,
    },
  );

  useEffect(
    () => {
      if(isLoggedIn && currentUserError !== undefined) {
        setLoggedIn(false);
        localStorage.removeItem('session_id');
        window.location.reload();
      }
    },
    [
      isLoggedIn,
      currentUserError,
    ],
  );

  if(currentUserLoading) {
    return (
      <Loader />
    );
  }

  return (
    <RouterProvider router={MainRouter} />
  );
};

/* Export App component -------------------------------- */
export default App;
