/* Framework imports ----------------------------------- */
import React, {
  createContext,
  useContext,
} from 'react';

/* Module imports -------------------------------------- */
import { useJsApiLoader } from '@react-google-maps/api';

/* Type imports ---------------------------------------- */
import type { UseLoadScriptOptions } from '@react-google-maps/api/dist/useLoadScript';
import type { GoogleMapsContextValue } from '../types/GoogleMapsContextValue';

const GoogleMapsContext = createContext<GoogleMapsContextValue>(
  {
    isLoaded: false,
  },
);

/* GoogleMapsProvider component prop types ------------- */
interface GoogleMapsProviderProps extends UseLoadScriptOptions {
  children: React.ReactNode;
}

/* GoogleMapsProvider component ------------------------ */
export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = (
  {
    children,
    ...loadScriptOptions
  },
) => {
  const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions as UseLoadScriptOptions);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = (): {
  isLoaded: boolean;
  loadError?: Error;
} => useContext(GoogleMapsContext);
