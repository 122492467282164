/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import List from '@material-ui/core/List';
import DriverListItem from './DriverListItem';

/* Type imports ---------------------------------------- */
import type { DriverData } from '../../../../types/DriverData';

/* DriverList component prop types --------------------- */
interface DriverListPropTypes {
  driversList?: DriverData[];
  setSelectedDriverID: (pDriverID: number) => void;
}

/* DriverList component -------------------------------- */
const DriverList: React.FC<DriverListPropTypes> = (
  {
    driversList = [],
    setSelectedDriverID,
  },
) => {
  return (
    <List>
      {
        driversList.length > 0 ?
          driversList.map(
            (pDriver: DriverData) => {
              const selectDriver: React.MouseEventHandler<HTMLDivElement> = (pEvent) => {
                setSelectedDriverID(pDriver.id);
              };

              return (
                <DriverListItem
                  key={pDriver.id}
                  driverData={pDriver}
                  onClick={selectDriver}
                />
              );
            },
          ) :
          <div>
            {`No drivers found...`}
          </div>
      }
    </List>
  );
};

/* Export DriverList component ------------------------- */
export default DriverList;
