/* Framework imports ----------------------------------- */
import React from 'react';
import {
  Navigate,
  useLocation,
} from 'react-router-dom';

/* Module imports -------------------------------------- */
import { useLoggedInQuery } from '../apollo/queries';

/* Component imports ----------------------------------- */

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */

/* RequireAuth component prop types -------------------- */
interface RequireAuthProps {
  children: JSX.Element;
}

/* RequireAuth component ------------------------------- */
const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation();

  const { data: loggedInData } = useLoggedInQuery();

  console.log(`[DEBUG] <RequireAuth> loggedInData :`, loggedInData);
  console.log(`[DEBUG] <RequireAuth> path :`, location.pathname);

  if(loggedInData?.isLoggedIn !== true) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to="/sign-in"
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

/* Export RequireAuth component ------------------------ */
export default RequireAuth;
