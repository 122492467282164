/* Framework imports ----------------------------------- */
import React from 'react';
import ReactDOM from 'react-dom';

/* Module imports -------------------------------------- */
import reportWebVitals from './reportWebVitals';
import { computeLocalQueryPolicies } from './utils/store/store';
import { GOOGLE_CONFIG } from './config/google.config';

/* Component imports ----------------------------------- */
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GoogleMapsProvider } from './utils/GoogleMapsProvider';
import { GoogleGeocoderProvider } from './utils/GoogleGeocoderProvider';
import App from './App/App';

/* Styling imports ------------------------------------- */
import { mainTheme } from './theme/mainTheme';
import './theme/index.css';

/* Configuration imports ------------------------------- */
import ERP_SERVER_CONFIG from './config/odooServer.config.json';

/* Helper functions ------------------------------------ */
const httpLink = createHttpLink(
  {
    uri: ERP_SERVER_CONFIG.ODOO_HOST + ERP_SERVER_CONFIG.GRAPHQL_ENDPOINT,
  },
);

const authLink = setContext(
  (_, { headers }: {headers: { [key: string]: string }}) => {
  // get the authentication token from local storage if it exists
    const token = localStorage.getItem('session_id');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'X-Openerp-Session-Id': token !== null && token !== undefined && token !== '' ? `${token}` : '',
      },
    };
  },
);

const apolloClient = new ApolloClient(
  {
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(
      {
        typePolicies: {
          Query: {
            fields: computeLocalQueryPolicies(),
          },
        },
      },
    ),
  },
);

/* Render the application ------------------------------ */
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <GoogleMapsProvider
          language="en"
          googleMapsApiKey={GOOGLE_CONFIG.mapsAPIKey}
          libraries={GOOGLE_CONFIG.libraries}
        >
          <GoogleGeocoderProvider>
            <App />
          </GoogleGeocoderProvider>
        </GoogleMapsProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
