/* Configuration imports ------------------------------- */
import ERP_SERVER_CONFIG from '../config/odooServer.config.json';

/* Type imports ---------------------------------------- */

/* Type declarations ----------------------------------- */
interface LoginCreds {
  email: string;
  password: string;
}

interface OdooResponse {
  error?: string;
  result?: Record<string, unknown>;
}

interface OdooAuthResponse extends OdooResponse {
  result: {
    // eslint-disable-next-line camelcase
    session_id: string;
  };
}

/* Authentication functions ---------------------------- */
const checkStatus = (pResponse: Response) => {
  if(pResponse.ok) {
    // response.status >= 200 && response.status < 300
    return pResponse;
  } else {
    throw new Error(`HTTP Error Response: ${pResponse.status} ${pResponse.statusText}`);
  }
};

const odooRequest = async (route: string, body: Record<string, unknown>): Promise<OdooResponse> => {
  try {
    const token = localStorage.getItem('session_id');

    const customHeader: HeadersInit = token !== null && token !== undefined && token !== '' ?
      {
        'X-Openerp-Session-Id': token,
      } :
      {};

    const lResponse: Response = await fetch(
      `${ERP_SERVER_CONFIG.ODOO_HOST}${route}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...customHeader,
        },
      },
    );

    checkStatus(lResponse);

    return await lResponse.json() as OdooResponse;
  } catch(pException) {
    console.error(`[DEBUG] <odooRequest> Odoo request failed :`, pException);
    throw pException;
  }
};

export const logout = async (): Promise<void> => {
  const lOdooRequestResponse: OdooResponse = await odooRequest(
    '/web/session/signout',
    {},
  );

  if(
    lOdooRequestResponse.error !== undefined &&
    lOdooRequestResponse.error !== null
  ) {
    throw lOdooRequestResponse.error;
  } else {
    document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    localStorage.removeItem('session_id');
  }

  return;
};

export const login = async (pLoginCreds: LoginCreds): Promise<void> => {
  const lOdooRequestResponse: OdooAuthResponse = await odooRequest(
    '/web/session/authenticate',
    {
      params: {
        login: pLoginCreds.email,
        password: pLoginCreds.password,
        db: ERP_SERVER_CONFIG.ODOO_DATABASE,
      },
    },
  ) as OdooAuthResponse;

  if(
    lOdooRequestResponse.error !== undefined &&
    lOdooRequestResponse.error !== null
  ) {
    throw lOdooRequestResponse.error;
  }
  if(lOdooRequestResponse.result.session_id) {
    localStorage.setItem('session_id', lOdooRequestResponse.result.session_id);
  }

  return;
};
