/* Framework imports ----------------------------------- */
import React, {
  createContext,
  useContext,
} from 'react';

/* Type imports ---------------------------------------- */
import type { GoogleGeocoderContextValue } from '../types/GoogleGeocoderContextValue';
import { useGoogleMaps } from './GoogleMapsProvider';

const GoogleGeocoderContext = createContext<GoogleGeocoderContextValue>(
  {
    isLoaded: false,
  },
);

/* GoogleMapsProvider component prop types ------------- */
interface GoogleMapsProviderProps {
  children: React.ReactNode;
}

/* GoogleMapsProvider component ------------------------ */
export const GoogleGeocoderProvider: React.FC<GoogleMapsProviderProps> = ({ children }) => {
  const { isLoaded: isGoogleLoaded } = useGoogleMaps();

  return (
    <GoogleGeocoderContext.Provider value={
      {
        isLoaded: isGoogleLoaded,
        geocoder: isGoogleLoaded ? new google.maps.Geocoder() : undefined,
      }
    }
    >
      {children}
    </GoogleGeocoderContext.Provider>
  );
};

export const useGoogleGeocoder = (): GoogleGeocoderContextValue => {
  return useContext(GoogleGeocoderContext);
};
