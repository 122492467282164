/* Framework imports ----------------------------------- */
import React, { useMemo } from 'react';

/* Module imports -------------------------------------- */
import { formatOdooDate } from '../../../utils/formatOdooDate';

/* Component imports ----------------------------------- */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

/* Type imports ---------------------------------------- */
import type { DriverData } from '../../../types/DriverData';

/* Style imports --------------------------------------- */
import './DriverInfoTable.css';

/* Type declarations ----------------------------------- */
interface DriverInfoTableRowData {
  text: string;
  value?: string | number | boolean | null;
}

/* DriverInfoTable component prop types ---------------- */
interface DriverInfoTableProps {
  selectedDriver: DriverData;
}

/* DriverInfoTable component --------------------------- */
const DriverInfoTable: React.FC<DriverInfoTableProps> = ({ selectedDriver }) => {

  const rows: DriverInfoTableRowData[] = useMemo(
    (): DriverInfoTableRowData[] => {
      return [
        {
          text: 'Latitude',
          value: selectedDriver.location?.coords?.latitude,
        },
        {
          text: 'Longitude',
          value: selectedDriver.location?.coords?.longitude,
        },
        {
          text: 'Last update',
          value: selectedDriver.lastPosDate !== null &&
            selectedDriver.lastPosDate !== undefined ?
            formatOdooDate(selectedDriver.lastPosDate) :
            selectedDriver.lastPosDate,
        },
        {
          text: 'Moving',
          value: selectedDriver.location?.is_moving,
        },
        {
          text: 'Activity',
          value: selectedDriver.location?.activity?.type,
        },
      ];
    },
    [
      selectedDriver,
    ],
  );

  return (
    <TableContainer
      component="div"
      classes={
        {
          root: 'DriverInfoTable',
        }
      }
    >
      <Table size="small">
        <TableBody>
          {
            rows.map(
              (pRow: DriverInfoTableRowData) => {
                if(pRow.value === undefined || pRow.value === null) {
                  return null;
                }

                return (
                  <TableRow key={pRow.text}>
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {pRow.text}
                    </TableCell>
                    <TableCell align="right">
                      {`${pRow.value}`}
                    </TableCell>
                  </TableRow>
                );
              },
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/* Export DriverInfoTable component -------------------- */
export default DriverInfoTable;
