/* Framework imports ----------------------------------- */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

/* Module imports -------------------------------------- */
import { useGoogleMaps } from '../../../utils/GoogleMapsProvider';
import { DEFAULT_MAP_CENTER } from '../../../utils/defines';

/* Component imports ----------------------------------- */
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  InfoWindow,
} from '@react-google-maps/api';
import Button from '@material-ui/core/Button';
import DriverInfoWindow from './DriverInfoWindow';

/* Type imports ---------------------------------------- */
import type { DriverData } from '../../../types/DriverData';

/* Local static variables ------------------------------ */
const sGoogleMapsContainerStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const sGoogleMapsOptions = {
  draggable: true, // make map draggable
  zoomControl: true,
  zoomControlOptions: {
    position: 9,
  },
  keyboardShortcuts: false, // disable keyboard shortcuts
  mapTypeControl: false,
  scaleControl: true, // allow scale control
  scrollwheel: true, // allow scroll wheel
  rotateControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  // styles: 'satellite', // change default map styles,
  mapTypeId: 'hybrid',
};

/* DriverSupervisionMap component prop types ----------- */
interface DriverSupervisionMapProps {
  isLoadingDriversList?: boolean;
  driversList: DriverData[];
  selectedDriverID: number;
  setSelectedDriverID: (pDriverID: number) => void;
}

/* DriverSupervisionMap component ---------------------- */
const DriverSupervisionMap: React.FC<DriverSupervisionMapProps> = (
  {
    isLoadingDriversList = false,
    driversList,
    selectedDriverID,
    setSelectedDriverID,
  },
) => {
  const { isLoaded: isGoogleScriptsLoaded } = useGoogleMaps();

  const [ oldDriverListLength, setOldDriverListLength ] = useState<number>(0);

  const mapInstanceRef = useRef<google.maps.Map>();
  const searchBoxInstanceRef = useRef<google.maps.places.SearchBox>();

  const updateMapCenterState = useCallback(
    (): void => {
      const lMapCenter = mapInstanceRef.current?.getBounds()?.getCenter().toJSON();

      if(lMapCenter) {
        // mapInstanceRef.current?.setCenter(lMapCenter);

        // console.log(`[DEBUG] <DriverSupervisionMap.updateMapCenterState> New map center :`, lMapCenter);
      }
    },
    [],
  );

  const onMapLoad = useCallback(
    (pMapInstance: google.maps.Map) => {
      mapInstanceRef.current = pMapInstance;

      pMapInstance.setTilt(0);
    },
    [],
  );

  const onSearchBoxLoad = useCallback(
    (pSearchBoxInstance: google.maps.places.SearchBox) => {
      searchBoxInstanceRef.current = pSearchBoxInstance;
    },
    [],
  );

  const onPlacesChanged = useCallback(
    () => {
      if(searchBoxInstanceRef.current !== undefined) {
        const lPlaces = searchBoxInstanceRef.current.getPlaces();

        if(lPlaces?.[0]?.geometry?.location) {
          const lNewCenter = {
            lat: lPlaces[0].geometry.location.lat(),
            lng: lPlaces[0].geometry.location.lng(),
          };

          mapInstanceRef.current?.setCenter(lNewCenter);
        }
      }
    },
    [],
  );

  const onMarkerClicked = (pDriverID: number) => (pEvent: google.maps.MapMouseEvent): void => {
    setSelectedDriverID(pDriverID);
  };

  const onInfoWindowClosed = () => {
    setSelectedDriverID(-1);
  };

  const computeMapBounds = useCallback(
    () => {
      if(isGoogleScriptsLoaded) {
        if(driversList.length > 0) {
          /* Set the initial bounds of the map */
          const lInitialBounds = new google.maps.LatLngBounds();

          for(let i = 0; i < driversList.length; ++i) {
            const lDriverData: DriverData = driversList[i];

            if(
              lDriverData.location?.coords?.latitude !== undefined &&
              lDriverData.location?.coords?.latitude !== null &&
              lDriverData.location?.coords?.longitude !== undefined &&
              lDriverData.location?.coords?.longitude !== null
            ) {
              const lDriverGeolocation: google.maps.LatLngLiteral = {
                lat: lDriverData.location.coords.latitude,
                lng: lDriverData.location.coords.longitude,
              };

              lInitialBounds.extend(lDriverGeolocation);
            }
          }

          if(mapInstanceRef.current !== undefined) {
            mapInstanceRef.current.fitBounds(lInitialBounds);

            const lCurrentZoomLevel: number | undefined = mapInstanceRef.current.getZoom();

            if(lCurrentZoomLevel === undefined || lCurrentZoomLevel > 14) {
              mapInstanceRef.current.setZoom(14);
            }
          }
        } else {
          if(mapInstanceRef.current !== undefined) {
            mapInstanceRef.current.setZoom(12);
            mapInstanceRef.current?.setCenter(DEFAULT_MAP_CENTER);
          }
        }
      }
    },
    [
      driversList,
      isGoogleScriptsLoaded,
    ],
  );

  const onCenterMapButtonClicked: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (pEvent) => {
      computeMapBounds();
    },
    [
      computeMapBounds,
    ],
  );

  useEffect(
    () => {
      if(oldDriverListLength !== driversList.length && !isLoadingDriversList) {
        console.log(`[DEBUG] <SupervisionMap> Recomputing map bounds...`);
        computeMapBounds();

        /* Save the driversList.length */
        setOldDriverListLength(driversList.length);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      computeMapBounds,
      /**
       * This reloads when a new driver is available,
       * which isn't really often.
       *
       * There are three cases in which we recompute the bounds of the map :
       * - When the number of drivers has changed
       * - When the GraphQL query loads the driversList
       */
      isLoadingDriversList,
      driversList.length,
      // oldDriverListLength, /* Will retrigger the useEffect, but the if condition nulls it */
    ],
  );

  const selectedDriver: DriverData | undefined = driversList.find(
    (pDriverData: DriverData) => {
      return pDriverData.id === selectedDriverID;
    },
  );

  if(isGoogleScriptsLoaded) {
    return (
      <GoogleMap
        id="simulator-google-map"
        center={DEFAULT_MAP_CENTER}
        zoom={12}
        clickableIcons={false}
        mapContainerStyle={sGoogleMapsContainerStyle}
        onLoad={onMapLoad}
        onDrag={updateMapCenterState}
        onZoomChanged={updateMapCenterState}
        options={sGoogleMapsOptions}
      >
        <StandaloneSearchBox
          onPlacesChanged={onPlacesChanged}
          onLoad={onSearchBoxLoad}
        >
          <input
            type="text"
            placeholder="Rechercher un lieu"
            style={
              {
                position: 'absolute',
                boxSizing: 'border-box',
                border: '1px solid transparent',
                width: '240px',
                height: '32px',
                margin: '15px',
                padding: '0 12px',
                borderRadius: '3px',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                fontSize: '14px',
                outline: 'none',
                textOverflow: 'ellipses',
                left: '0px',
                top: '0px',
                zIndex: 999,
              }
            }
          />
        </StandaloneSearchBox>
        {
          driversList?.map(
            (pDriverData: DriverData): React.ReactNode => {
              if(
                pDriverData.location?.coords?.latitude !== undefined &&
                pDriverData.location?.coords?.latitude !== null &&
                pDriverData.location?.coords?.longitude !== undefined &&
                pDriverData.location?.coords?.longitude !== null
              ) {

                const lMarkerCoords: google.maps.LatLngLiteral = {
                  lat: pDriverData.location.coords.latitude,
                  lng: pDriverData.location.coords.longitude,
                };

                return (
                  <>
                    <Marker
                      key={`primary-${pDriverData.id}`}
                      position={lMarkerCoords}
                      label={
                        {
                          text: `${pDriverData.reference}`,
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: '18px',
                        }
                      }
                      icon={
                        { 
                          url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                          labelOrigin: new google.maps.Point(17, -12),
                          size: new google.maps.Size(32, 32),
                          anchor: new google.maps.Point(16, 32),
                        }
                      }
                      title={pDriverData.name ?? undefined}
                      onClick={onMarkerClicked(pDriverData.id)}
                    />
                    <Marker
                      key={`secondary-${pDriverData.id}`}
                      position={lMarkerCoords}
                      label={
                        {
                          text: `${pDriverData.reference}`,
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '18px',

                        }
                      }
                      icon={
                        { 
                          url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                          labelOrigin: new google.maps.Point(15, -10),
                          size: new google.maps.Size(32, 32),
                          anchor: new google.maps.Point(16, 32),
                        }
                      }
                      title={pDriverData.name ?? undefined}
                      onClick={onMarkerClicked(pDriverData.id)}
                    />
                    <Marker
                      key={`tertiary-${pDriverData.id}`}
                      position={lMarkerCoords}
                      icon={
                        {
                          url: 'https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle.png',
                          size: new google.maps.Size(7, 7),
                          anchor: new google.maps.Point(4, 4),
                        }
                      }
                    />
                  </>
                );
              } else {
                return null;
              }
            },
          )
        }
        {
          selectedDriver !== undefined &&
          // infoWindowOpen &&
            <InfoWindow
              position={
                {
                  lat: selectedDriver.location?.coords?.latitude ?? 0,
                  lng: selectedDriver.location?.coords?.longitude ?? 0,
                }
              }
              onCloseClick={onInfoWindowClosed}
            >
              <DriverInfoWindow selectedDriver={selectedDriver} />
            </InfoWindow>
        }
        {
          driversList.length > 0 &&
            <div
              style={
                {
                  position: 'absolute',
                  left: 'calc(50% - 87px)',
                  bottom: '0%',
                }
              }
            >
              <Button
                size="large"
                color="default"
                variant="contained"
                disabled={driversList.length < 1}
                onClick={onCenterMapButtonClicked}
                style={
                  {
                    padding: '0px 20px',
                    margin: '0 0 10px 0',
                  }
                }
              >
                Center the map
              </Button>
            </div>
        }
      </GoogleMap>
    );
  } else {
    return (
      <div>
        Chargement de Google Maps...
      </div>
    );
  }
};

/* Export DriverSupervisionMap component --------------- */
export default DriverSupervisionMap;
