/* Framework imports ----------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';

/* Module imports -------------------------------------- */
import * as Luq from '@bold/luq-client';
import { useSearchDriverQuery } from '../../apollo/queries';
import { odooLuqCompile } from '../../utils/odooLuqCompile';

/* Component imports ----------------------------------- */
import DriverSearchSideBar from './DriverSearchSideBar/DriverSearchSideBar';
import DriverSupervisionMap from './DriverSupervisionMap/DriverSupervisionMap';

/* Type imports ---------------------------------------- */
import type { ApolloError } from '@apollo/client';
import type { DriverData } from '../../types/DriverData';
import type {
  EventFieldsFragment,
  PartnerFieldsFragment,
  SearchDriverQuery,
} from '../../apollo/queries/types';

/* Styling imports ------------------------------------- */

/* Styled components ----------------------------------- */
const SupervisionPageContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: stretch;
`;

const GoogleMapsSection = styled.section`
  flex: 3;
`;

const DriverListAside = styled.aside`
  flex: 1;

  overflow-y: auto;
`;

/* SupervisionPage component --------------------------- */
const SupervisionPage: React.FC = () => {

  const [ selectedDriverID, setSelectedDriverIDState ] = useState<number>(-1);
  const [ searchBarContents, setSearchBarContents ] = useState<string>('');
  const [ selectedPartner, setSelectedPartner ] = useState<number>(0);
  const [ selectedEvent, setSelectedEvent ] = useState<number>(0);

  const onSearchDriverQueryError = (pError: ApolloError): void => {
    console.error(`[ERROR] <SupervisionPage> SearchDriverQuery got an error :`, pError);
  };

  const onSearchDriverQueryCompleted = (pData: SearchDriverQuery): void => {
    // console.log(`[DEBUG] <SupervisionPage> SearchDriverQuery completed :`, pData);
  };

  const {
    data: driversListData,
    loading: isLoadingDriversList,
  } = useSearchDriverQuery(
    {
      variables: {
        filter: odooLuqCompile(
          <Luq.And>
            <Luq.Eq
              field="role"
              value="driver"
            />
            <Luq.Neq
              field="current_ride_id"
              value={false}
            />
            <Luq.Or>
              <Luq.Neq
                field="long"
                value={0}
              />
              <Luq.Neq
                field="lat"
                value={0}
              />
            </Luq.Or>
          </Luq.And>,
        ),
      },
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      onError: onSearchDriverQueryError,
      onCompleted: onSearchDriverQueryCompleted,
    },
  );

  const onSelectDriver = (pDriverID: number): void => {
    if(selectedDriverID === pDriverID) {
      setSelectedDriverIDState(-1);
    } else {
      setSelectedDriverIDState(pDriverID);
    }
  };

  const driversList: DriverData[] = useMemo(
    () => {
      if(driversListData && driversListData.searchResUsers.length > 0) {
        if(selectedEvent === 0) {
          setSelectedEvent(((driversListData.searchResUsers[0].currentRideId?.eventId?.id) ?? 0) || 0);
        }
        return driversListData.searchResUsers.map((driver) => ({
          id: driver.id,
          name: driver.name ?? 'Unknown',
          firstname: driver.firstname,
          reference: driver.reference as string|undefined,
          displayName: driver.displayName,
          location: {
            coords: {
              latitude: driver.lat,
              longitude: driver.long,
            },
          },
          lastPosDate: driver.lastPosDate,
          currentRideId: driver.currentRideId,
        }));
      } else {
        return [];
      }
    },
    [
      driversListData,
    ],
  );
  
  const events = useMemo<EventFieldsFragment[]>(
    () => {
      return driversList.reduce<EventFieldsFragment[]>(
        (acc, driver) => {
          if(driver.currentRideId?.eventId !== null && driver.currentRideId?.eventId !== undefined && !acc.includes(driver.currentRideId.eventId)) {
            return [
              ...acc,
              driver.currentRideId.eventId,
            ];
          } else {
            return acc;
          }
        },
        [],
      );
    },
    [ driversList ],
  );

  const partners = useMemo<PartnerFieldsFragment[]>(
    () => {
      return driversList.reduce<PartnerFieldsFragment[]>(
        (acc, driver) => {
          if(driver.currentRideId?.parentId !== null && driver.currentRideId?.parentId !== undefined && !acc.includes(driver.currentRideId.parentId) && (selectedEvent === 0 || driver.currentRideId?.eventId?.id === selectedEvent)) {
            return [
              ...acc,
              driver.currentRideId.parentId,
            ];
          } else {
            return acc;
          }
        },
        [],
      );
    },
    [
      driversList,
      selectedEvent,
    ],
  );

  const filteredDriversList: DriverData[] = useMemo(
    () => {
      const lFilterResults = driversList.filter(
        (driver: DriverData): boolean => {
          /* Does this driver have a name ? */
          if(driver.reference === undefined || driver.reference === null) {
            console.log('missing reference');
            return false;
          } else if(selectedPartner !== 0 && driver.currentRideId?.parentId?.id !== selectedPartner) {
            console.log('Wrong partner');
            return false;
          } else if(selectedEvent !== 0 && driver.currentRideId?.eventId?.id !== selectedEvent) {
            console.log('Wrong event');
            return false;
          } else if(driver.location?.coords?.latitude === 0 && driver.location?.coords?.longitude === 0) {
            console.log('coordinates are at 0.');
            return false;
          } else {
            console.log('Verifying search content');
            return driver.reference
              .toLowerCase()
              .includes(
                searchBarContents.toLowerCase(),
              );
          }
        },
      );

      return lFilterResults;
    },
    [
      searchBarContents,
      driversList,
      selectedPartner,
      selectedEvent,
    ],
  );

  return (
    <SupervisionPageContainer>
      <GoogleMapsSection>
        <DriverSupervisionMap
          isLoadingDriversList={isLoadingDriversList}
          driversList={filteredDriversList}
          selectedDriverID={selectedDriverID}
          setSelectedDriverID={onSelectDriver}
        />
      </GoogleMapsSection>
      <DriverListAside>
        <DriverSearchSideBar
          driversList={filteredDriversList}
          selectedDriverID={selectedDriverID}
          setSelectedDriverID={onSelectDriver}
          searchBarContents={searchBarContents}
          setSearchBarContents={setSearchBarContents}
          selectedPartner={selectedPartner}
          setSelectedPartner={setSelectedPartner}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          partners={partners}
          events={events}
        />
      </DriverListAside>
    </SupervisionPageContainer>
  );
};

/* Export SupervisionPage component -------------------- */
export default SupervisionPage;
