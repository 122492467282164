/* Framework imports ----------------------------------- */
import React from 'react';
import { makeStyles } from '@material-ui/core';

/* Component imports ----------------------------------- */
import CircularProgress from '@material-ui/core/CircularProgress';

/* Internal constants ---------------------------------- */
const loaderSize = '40px';

/* Loader component styling ---------------------------- */
const useStyles = makeStyles(
  (pTheme) => {
    return {
      wrapper: {
        position: 'absolute',
        top: `calc(50% - ${loaderSize})`,
        left: `calc(50% - ${loaderSize})`,
      },
    };
  },
);

/* Loader component ------------------------------------ */
const Loader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress />
    </div>
  );
};

/* Export Loader component ----------------------------- */
export default Loader;
