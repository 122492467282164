interface GoogleMapsConfig {
  mapsAPIKey: string;
  libraries: ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
}

export const GOOGLE_CONFIG: GoogleMapsConfig = {
  mapsAPIKey: 'AIzaSyDbU__uni-JnNf-JuKpFp5ZYkWKYsNtc70',
  libraries: [
    'places',
    'geometry',
    'drawing',
  ],
};
