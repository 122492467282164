import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {};
export const CurrencyFieldsFragmentDoc = gql`
  fragment CurrencyFields on ResCurrency {
    id
    name
    symbol
  }
`;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on ResPartner {
    id
    name
    currency {
      id
      name
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ResUsers {
    id
    name
    firstname
    reference
    role {
      key
    }
    parentId {
      ...PartnerFields
    }
    divisionId {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on CommunicorEvent {
    id
    name
    startDate
    endDate
    website
  }
`;
export const RideFieldsFragmentDoc = gql`
  fragment RideFields on CommunicorRide {
    id
    passengerId {
      ...UserFields
    }
    parentId {
      ...PartnerFields
    }
    divisionId {
      ...PartnerFields
    }
    eventId {
      ...EventFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${PartnerFieldsFragmentDoc}
  ${EventFieldsFragmentDoc}
`;
export const DriverFieldsFragmentDoc = gql`
  fragment DriverFields on ResUsers {
    id
    firstname
    name
    reference
    displayName
    role {
      key
    }
    parentId {
      ...PartnerFields
    }
    divisionId {
      ...PartnerFields
    }
    lat
    long
    lastPosDate
    currentRideId {
      ...RideFields
    }
  }
  ${PartnerFieldsFragmentDoc}
  ${RideFieldsFragmentDoc}
`;
export const VehicleFieldsFragmentDoc = gql`
  fragment VehicleFields on CommunicorVehicleType {
    id
    name
    description
  }
`;
export const CurrentUserDocument = gql`
  query CurrentUser {
    getMyData {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type CurrentUserProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >;
} & TChildProps;
export function withCurrentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >(CurrentUserDocument, {
    alias: "currentUser",
    ...operationOptions,
  });
}

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  Types.CurrentUserQuery,
  Types.CurrentUserQueryVariables
>;
export const GetEventsListDocument = gql`
  query getEventsList {
    searchCommunicorEvent {
      id
      name
      startDate
      endDate
    }
  }
`;
export type GetEventsListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >;
} & TChildProps;
export function withGetEventsList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables,
    GetEventsListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables,
    GetEventsListProps<TChildProps, TDataName>
  >(GetEventsListDocument, {
    alias: "getEventsList",
    ...operationOptions,
  });
}

/**
 * __useGetEventsListQuery__
 *
 * To run a query within a React component, call `useGetEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >(GetEventsListDocument, options);
}
export function useGetEventsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetEventsListQuery,
    Types.GetEventsListQueryVariables
  >(GetEventsListDocument, options);
}
export type GetEventsListQueryHookResult = ReturnType<
  typeof useGetEventsListQuery
>;
export type GetEventsListLazyQueryHookResult = ReturnType<
  typeof useGetEventsListLazyQuery
>;
export type GetEventsListQueryResult = Apollo.QueryResult<
  Types.GetEventsListQuery,
  Types.GetEventsListQueryVariables
>;
export const FindIbBasketsDocument = gql`
  query findIBBaskets($filter: String!, $limit: Int, $order: String) {
    searchCommunicorBasket(filter: $filter, limit: $limit, order: $order) {
      id
      state {
        key
        value
      }
    }
  }
`;
export type FindIbBasketsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >;
} & TChildProps;
export function withFindIbBaskets<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables,
    FindIbBasketsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables,
    FindIbBasketsProps<TChildProps, TDataName>
  >(FindIbBasketsDocument, {
    alias: "findIbBaskets",
    ...operationOptions,
  });
}

/**
 * __useFindIbBasketsQuery__
 *
 * To run a query within a React component, call `useFindIbBasketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindIbBasketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIbBasketsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindIbBasketsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >(FindIbBasketsDocument, options);
}
export function useFindIbBasketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindIbBasketsQuery,
    Types.FindIbBasketsQueryVariables
  >(FindIbBasketsDocument, options);
}
export type FindIbBasketsQueryHookResult = ReturnType<
  typeof useFindIbBasketsQuery
>;
export type FindIbBasketsLazyQueryHookResult = ReturnType<
  typeof useFindIbBasketsLazyQuery
>;
export type FindIbBasketsQueryResult = Apollo.QueryResult<
  Types.FindIbBasketsQuery,
  Types.FindIbBasketsQueryVariables
>;
export const LoggedInDocument = gql`
  query LoggedIn {
    isLoggedIn @client
  }
`;
export type LoggedInProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >;
} & TChildProps;
export function withLoggedIn<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables,
    LoggedInProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables,
    LoggedInProps<TChildProps, TDataName>
  >(LoggedInDocument, {
    alias: "loggedIn",
    ...operationOptions,
  });
}

/**
 * __useLoggedInQuery__
 *
 * To run a query within a React component, call `useLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LoggedInQuery, Types.LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export function useLoggedInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LoggedInQuery, Types.LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export type LoggedInQueryHookResult = ReturnType<typeof useLoggedInQuery>;
export type LoggedInLazyQueryHookResult = ReturnType<
  typeof useLoggedInLazyQuery
>;
export type LoggedInQueryResult = Apollo.QueryResult<
  Types.LoggedInQuery,
  Types.LoggedInQueryVariables
>;
export const SearchDriverDocument = gql`
  query SearchDriver($filter: String!, $limit: Int, $order: String) {
    searchResUsers(filter: $filter, limit: $limit, order: $order) {
      ...DriverFields
    }
  }
  ${DriverFieldsFragmentDoc}
`;
export type SearchDriverProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchDriverQuery,
    Types.SearchDriverQueryVariables
  >;
} & TChildProps;
export function withSearchDriver<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchDriverQuery,
    Types.SearchDriverQueryVariables,
    SearchDriverProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchDriverQuery,
    Types.SearchDriverQueryVariables,
    SearchDriverProps<TChildProps, TDataName>
  >(SearchDriverDocument, {
    alias: "searchDriver",
    ...operationOptions,
  });
}

/**
 * __useSearchDriverQuery__
 *
 * To run a query within a React component, call `useSearchDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDriverQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchDriverQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchDriverQuery,
    Types.SearchDriverQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchDriverQuery,
    Types.SearchDriverQueryVariables
  >(SearchDriverDocument, options);
}
export function useSearchDriverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchDriverQuery,
    Types.SearchDriverQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchDriverQuery,
    Types.SearchDriverQueryVariables
  >(SearchDriverDocument, options);
}
export type SearchDriverQueryHookResult = ReturnType<
  typeof useSearchDriverQuery
>;
export type SearchDriverLazyQueryHookResult = ReturnType<
  typeof useSearchDriverLazyQuery
>;
export type SearchDriverQueryResult = Apollo.QueryResult<
  Types.SearchDriverQuery,
  Types.SearchDriverQueryVariables
>;
export const SearchUsersDocument = gql`
  query SearchUsers($filter: String!, $limit: Int, $order: String) {
    searchResUsers(filter: $filter, limit: $limit, order: $order) {
      id
      name
      role {
        key
        value
      }
    }
  }
`;
export type SearchUsersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >;
} & TChildProps;
export function withSearchUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables,
    SearchUsersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables,
    SearchUsersProps<TChildProps, TDataName>
  >(SearchUsersDocument, {
    alias: "searchUsers",
    ...operationOptions,
  });
}

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >(SearchUsersDocument, options);
}
export function useSearchUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchUsersQuery,
    Types.SearchUsersQueryVariables
  >(SearchUsersDocument, options);
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<
  typeof useSearchUsersLazyQuery
>;
export type SearchUsersQueryResult = Apollo.QueryResult<
  Types.SearchUsersQuery,
  Types.SearchUsersQueryVariables
>;
