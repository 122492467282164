/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */
import { formatOdooDate } from '../../../../utils/formatOdooDate';

/* Component imports ----------------------------------- */
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';

/* Type imports ---------------------------------------- */
import type { DriverData } from '../../../../types/DriverData';

/* DriverListItem component prop types ----------------- */
interface DriverListItemProps {
  driverData: DriverData;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

/* DriverListItem component ---------------------------- */
const DriverListItem: React.FC<DriverListItemProps> = ({ driverData, onClick }) => {
  return (
    <ListItem
      button
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar>
          <LocalTaxiIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <span>
            {driverData.displayName}
          </span>
        }
        secondary={
          <span>
            {driverData.id}
            {
              driverData.lastPosDate !== undefined &&
              driverData.lastPosDate !== null ?
                <>
                  <br />
                  {`Last update : ${formatOdooDate(driverData.lastPosDate)}`}
                </> :
                null
            }
          </span>
        }
      />
    </ListItem>
  );
};

/* Export DriverListItem component --------------------- */
export default DriverListItem;
