/* Framework imports ----------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from '@emotion/styled';

/* Module imports -------------------------------------- */
import { logout } from '../../utils/authentication';
import setLoggedIn from '../../utils/store/loggedIn';

/* Component imports ----------------------------------- */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

/* Styling imports ------------------------------------- */

/* Styled components ----------------------------------- */
const MainStyled = styled.main`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AppBar = styled.header`
  width: 100%;
  min-height: 30px;

  display: flex;
  justify-content: space-around;

  box-shadow: none;
  transition: none;
  background-color: #171A1D;
  color: white;

  padding: 5px;
`;

const AppBarTitle = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  font-size: 28px;
  font-family: Karla;
  font-weight: 600;
  color: white;
  width: fit-content;
`;

const AppBarLogoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const AppBarLogo = styled.img`
  width: 250px;
  height: 60px;
`;

const AuthStatusDiv = styled.div`
  display: flex;
  align-items: center;
`;

const PageContainer = styled.div`
  flex: 1;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;

  overflow-y: auto;
`;

const MainFooter = styled.footer`
  width: 100%;
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: none;
  transition: none;
  background-color: #212529;
  color: white;

  padding: 5px;
`;

const FooterButton = styled(Button)`
  min-width: 168px;
  font-size: 0.75rem;
  text-transform: none;
`;

/* MainLayout component prop types --------------------- */
interface MainLayoutProps {}

/* MainLayout component -------------------------------- */
const MainLayout: React.FC<MainLayoutProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [ logoutInProgress, setLogoutInProgress ] = useState<boolean>(false);
  const [ logoutFailed, setLogoutFailed ] = useState<boolean>(false);

  const onLogoutClicked: React.MouseEventHandler<HTMLDivElement> = (pEvent) => {
    if(!logoutInProgress) {
      setLogoutInProgress(true);
      logout()
        .then(
          () => {
            setLogoutFailed(false);
            setLoggedIn(false);
            localStorage.removeItem('session_id');
            navigate(`/sign-in`);
            console.log(`[INFO ] <MainLayout> Successfully logged out`);
          },
        )
        .catch(
          (pException) => {
            console.error(`[ERROR] <MainLayout> Failed to log out :`, pException);
            setLogoutFailed(true);
          },
        )
        .finally(
          () => {
            setLogoutInProgress(false);
          },
        );
    }
  };

  const pageTitle: string | undefined = useMemo<string | undefined>(
    () => {
      if(location.pathname.startsWith('/sign-in')) {
        return 'Sign In';
      } else if(location.pathname.startsWith('/supervision')) {
        return 'Driver supervision';
      } else {
        return undefined;
      }
    },
    [
      location.pathname,
    ],
  );

  return (
    <MainStyled>
      <AppBar>
        <AppBarTitle>
          {pageTitle}
        </AppBarTitle>
        <AppBarLogoContainer>
          <AppBarLogo
            src="/assets/logo/Communicor_Logo_W&B.png"
            alt="Communicor"
          />
        </AppBarLogoContainer>
        <AuthStatusDiv
          onClick={
            logoutInProgress ?
              undefined :
              onLogoutClicked
          }
        >
          {
            !logoutInProgress ?
              'Log out' :
              <>
                {`Logging out...`}
                <CircularProgress
                  size="2rem"
                  style={{ marginLeft: '1rem' }}
                />
              </>
          }
        </AuthStatusDiv>
      </AppBar>
      <PageContainer>
        <Outlet />
      </PageContainer>
      <MainFooter>
        <Link
          className="rrd-nav-link"
          to="#"
        >
          <FooterButton variant="text">
            Privacy Policy
          </FooterButton>
        </Link>
        <Link
          className="rrd-nav-link"
          to="#"
        >
          <FooterButton variant="text">
            Terms of Use
          </FooterButton>
        </Link>
        <Link
          className="rrd-nav-link"
          to="#"
        >
          <FooterButton variant="text">
            Legal
          </FooterButton>
        </Link>
      </MainFooter>
      <Snackbar
        open={logoutFailed}
        autoHideDuration={6000}
        onClose={() => setLogoutFailed(false)}
      >
        <Alert
          onClose={() => setLogoutFailed(false)}
          variant="filled"
          severity="error"
        >
          Logout failed
        </Alert>
      </Snackbar>
    </MainStyled>
  );
};

/* Export MainLayout component ------------------------- */
export default MainLayout;
