import { makeStyles } from '@material-ui/core/styles';

const useSignInStyles = makeStyles(
  (pTheme) => {
    return {
      paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 144px)',
      },
      form: {
        width: '494px', // Fix IE 11 issue.
        marginTop: pTheme.spacing(1),
      },
      submit: {
        margin: '1rem',
      },
      margin: {
        marginBottom: '55px',
      },
    };
  },
);

export default useSignInStyles;
