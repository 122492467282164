/* formatOdooDate helper function implementation ------- */
export const formatOdooDate = (pOdooDate: string): string => {
  const lDate: Date = new Date(pOdooDate);

  return lDate.toLocaleDateString(
    'fr-FR',
    {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  );
};
