/* Framework imports ----------------------------------- */
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */
import MainLayout from '../layouts/MainLayout/MainLayout';
import SignInPage from '../pages/SignInPage/SignInPage';
import SupervisionPage from '../pages/SupervisionPage/SupervisionPage';
import RequireAuth from './RequireAuth';

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */

/* MainRouter component -------------------------------- */
const MainRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<MainLayout />}
    >
      <Route
        path="sign-in"
        element={<SignInPage />}
      />
      <Route
        path="supervision"
        element={
          <RequireAuth>
            <SupervisionPage />
          </RequireAuth>
        }
      />
      <Route
        index
        element={<Navigate to="supervision" />}
      />
    </Route>,
  ),
);

/* Export MainRouter component ------------------------- */
export default MainRouter;
