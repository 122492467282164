import { createTheme } from '@material-ui/core';

export const mainTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#ffffff',
      },
      secondary: {
        main: '#CED4DA',
      },
      background: {
        default: '#171A1D',
      },
      text: {
        primary: '#ffffff',
        secondary: '#CED4DA',
      },
    },
    typography: {
      fontFamily: [
        'Karla',
      ].join(', '),
      h1: {
        fontSize: '36px',
        fontFamily: 'Karla',
        fontWeight: 700,
      },
      h2: {
        fontSize: '26px',
        fontFamily: 'Karla',
        fontWeight: 700,
        color: '#CED4DA',
      },
      h3: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#CED4DA',
      },
      h4: {
        fontFamily: 'Montserrat',
        fontWeight: 300,
        color: '#CED4DA',
        fontSize: '16px',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: '#CED4DA',
          boxShadow: '0px 5px 10px #00000080',
          opacity: 1,
          borderRadius: '10px',
          fontSize: '20px',
          padding: '0px',
        },

      },
      MuiInputLabel: {
        outlined: {
          fontSize: '18px',
          fontWeight: 500,
          fontFamily: 'Montserrat',
          transform: 'translate(6px, -6px) scale(0.75) !important',
        },
      },
      MuiFormControl: {
        root: {
          marginBottom: '20px',
        },
      },
      MuiButton: {
        contained: {
          fontSize: '18px',
          fontWeight: 600,
          fontFamily: 'Montserrat',
          minWidth: '154px',
          minHeight: '60px',
          borderRadius: '10px',
          marginRight: '10px',
        },
      },
      MuiFormLabel: {
        root: {
          fontWeight: 400,
          fontSize: '18px',
          fontFamily: 'Montserrat',
        },
      },
      MuiPopover: {
        paper: {
          background: '#171A1D',
        },
      },
      MuiSelect: {
        select: {
          textAlign: 'left',
        },
      },
    },
  },
);
